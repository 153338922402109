<template>
    <v-row justify="center">
      <div v-for="(item,i) in response" :key="i">
        <div v-if="item.success">
        <v-col  cols="12" >
          <p class="subheading font-weight-regular">
        {{$t('thanks')}}<strong> ${{(item.success.amount_captured/100).toFixed(2)}} !! </strong>
        </p>
        </v-col>
        <v-col cols="12">
            <v-img
            :src="require('../assets/thank_you.svg')"
            contain
            height="200">
            </v-img>
        </v-col>
        <v-col cols="12" v-if="receipt">
          <div>
         <small class="subheading font-weight-bold">

        <a :href="item.success.receipt_url" target="_blank">{{$t('receipt')}} {{$t('here')}}</a>
        <v-icon small>launch</v-icon>
        </small>
        </div>
        <div class="mt-5">
        <small>
          {{$t('vca_note')}}
          <a href="https://vivacommuart.org/" target="_blank">Viva Communauté Art</a>
        <v-icon small>launch</v-icon>
        </small>
        </div>
        </v-col>
        </div>
        <div v-else>
          <v-col cols="12">
          <p class="subheading font-weight-regular">
        {{$t('went_wrong')}}
        </p>
        </v-col>
        <v-col cols="12">
            <v-img
            :src="require('../assets/something_wrong.svg')"
            contain
            height="200">
            </v-img>
        </v-col>
        <v-col cols="12">
         <h3 class="font-weight-regular error--text ">
       {{item.error}}
        </h3>
        </v-col>
        </div>
         </div>
    </v-row>
</template>
<script>
export default {
  name: 'landing-donation-thank-you',
  props: {
    response: {
      type: Array
    },
    receipt: {
      type: Boolean
    }
  }
}
</script>
<i18n>
{
  "en":{
    "thanks":"Thank you so much for your ",
    "receipt":"You can view and save your receipt",
    "vca_note":"designed and implemented by",
    "here":"here",
    "went_wrong":"Something went wrong !!"
  },
  "fr":{
    "thanks":"Merci beaucoup pour vos ",
    "receipt":"Vous pouvez visualiser et sauvegarder votre reçu",
    "vca_note":"conçu et implémenté par",
    "here":"ici",
    "went_wrong":"Oups! Une erreur est survenue !!"
  },
  "es":{
    "thanks":"¡Gracias por sus ",
    "receipt":"Puede ver y guardar su recibo",
    "vca_note":"diseñado y implementado por",
    "here":"aqui",
    "went_wrong":"¡Parece que surgió un error!"
  }
}
</i18n>
